import { Head } from 'components/common';
import { Media20231215 } from 'components/policy';
import PolicyLayout from 'layout/policy-layout';

const PolicyMedia = () => {
  return (
    <PolicyLayout>
      <Head title="서비스 이용 약관(매체 제휴)" />
      <Media20231215 />
    </PolicyLayout>
  );
};

export default PolicyMedia;
