import { Header, Menu } from 'components/policy';
import { ReactNode } from 'react';

interface PolicyLayoutProps {
  children: ReactNode;
}

const PolicyLayout = ({ children }: PolicyLayoutProps) => {
  return (
    <>
      <Header />
      <main>
        <Menu />
        {children}
      </main>
    </>
  );
};

export default PolicyLayout;
